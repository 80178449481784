import React from 'react'
import { Spinner, SpinnerProps } from './Spinner'
import { ErrorMsg, OpState } from 'app-fe-ui'

export function OpStatus<D>({
                                op,
                                spinnerSize,
                                className,
                            }: {
    op: OpState<D>
    className?: string
    spinnerSize?: SpinnerProps['size']
}) {
    return (
        <div className={className}>
            <Spinner shown={op.pending} size={spinnerSize} />
            <ErrorMsg error={op.error} />
        </div>
    )
}
