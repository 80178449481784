import React, { ComponentType, useEffect, useState } from 'react'
import { useAppContext } from '../ctx/MyContext'
import { observer } from 'mobx-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LOGIN_FAILED_QUERY_PARAM, LOGIN_SUCCESS_QUERY_PARAM } from './Auth.store'
import { css } from '@emotion/react'
import { Button } from '@fluentui/react-components'
import { ErrorMsg } from 'app-fe-ui'

export const AuthProvider: ComponentType<React.PropsWithChildren & {
    loginCb: string
    requireSession?: boolean
}> = observer(
    ({ children, requireSession = false, loginCb }) => {
        const { auth, app } = useAppContext()
        const store = auth.store

        const [searchParams, setSearchParams] = useSearchParams()
        const nav = useNavigate()

        const [error, setError] = useState<string | null>()

        const loginSuccess =
            searchParams.get(LOGIN_SUCCESS_QUERY_PARAM) === 'true'

        const loginFailed =
            searchParams.get(LOGIN_FAILED_QUERY_PARAM) === 'true'

        useEffect(() => {

            // Refresh the current user
            if (store.hasSession) {
                store.loadCurrentUser()
            }

            // No callback handling
            if (!loginFailed && !loginSuccess)
                return

            if (loginSuccess) {
                store.loadCurrentUser()
            } else if (loginFailed) {
                setError(searchParams.get('error_msg')!)
            }

            // Remove the query param from the URL
            searchParams.delete(LOGIN_SUCCESS_QUERY_PARAM)
            searchParams.delete(LOGIN_FAILED_QUERY_PARAM)
            nav({
                search: searchParams.toString()
            })

        }, [])

        // Wait until hydration
        if (!store.isHydrated)
            return null

        if (!store.currentUser && store.fetchingUser)
            return null

        // After logout avoid displaying the login form (this does happen)

        const sessionRequired = requireSession || !store.publicAccessAllowed
        const showLoginScreen = (!store.currentUser && sessionRequired) || error

        const canCancelLoginError = store.publicAccessAllowed && error

        if (showLoginScreen)
            return (
                <div css={css`
                    margin: auto;
                    width: 30em;
                    margin-top: 20em;
                `
                }>
                    <h2>Authentication required. Please log in.</h2>
                    <Button
                        appearance={'primary'}
                        onClick={() => {
                            const cb = loginCb
                            store.initiateLogin(cb)
                        }}
                    >
                        Login
                    </Button>
                    {canCancelLoginError && <Button
                        css={css`
                            margin-left: 1em;
                        `}
                        onClick={() => {
                            setError(null)
                        }}
                    >
                        Cancel
                    </Button>}
                    <div css={css`
                        margin-top: 1em;
                    `}>
                        <ErrorMsg
                            shown={!!error}>
                            {error || undefined}
                        </ErrorMsg>
                    </div>

                </div>
            )

        return <>{children}</>
    }
)
