/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AppInfo {
    version: string
}

export interface LlmConfigInfo {
    queryModel: string
    chatModel: string
}

export interface EmbedderConfigInfo {
    name: string
    displayName: string
    vectorSize: number
}

export interface VectorConfigInfo {
    embedders: EmbedderConfigInfo[]
}

export interface SearchConfigInfo {
    aiSearchEndpoint: string
}

export interface ConfigInfo {
    llm: LlmConfigInfo
    vector: VectorConfigInfo
    search: SearchConfigInfo
}

export interface UiThemeHeader {
    height?: string
    background: string
    textColor: string
    logoHeight: number
    titleFontSize?: string
}

export interface UiTheme {
    color: string
    borderRadius: string
    boxShadow: string
    header: UiThemeHeader
    outlineSelectionColor?: string
    hideCollectionPicker?: boolean
    ratingStarIcon: boolean
}

export interface FrontendConf {
    title: string
    version: string
    subapp?: string
    publicAccess: boolean
    flags: {
        sourceNamesFixer: boolean
    }
    branding: {
        logoUrl: string
        faviconUrl: string
        theme: UiTheme
    }
}

export interface LoginInitiateRes {
    redirect?: string
    done: boolean
}

export interface CurrentUser {
    name: string
    admin: boolean
}

export interface CollectionUpdateCmd {
    chatbotSettingsId: number
}

export enum SearchBackendType {
    VECTOR_OPEN_AI_PG = 'VECTOR_OPEN_AI_PG',
    AZURE_AI_SEARCH = 'AZURE_AI_SEARCH',
}

export enum PdfExtractorType {
    JAVA_HELPER_SERVICE = 'JAVA_HELPER_SERVICE',
    AZURE = 'AZURE',
}

export interface Collection {
    chatbotSettings: number
    searchBackend: SearchBackendType
    pdfExtractor: PdfExtractorType
    id: number
    name: string
}

export interface CollectionCreateCmd {
    searchBackend: SearchBackendType
    pdfExtractor: PdfExtractorType
    name: string
    chatbotSettingsId: number
}

export interface CollectionId {
    id: number
}

export interface SourceDocument {
    collection: CollectionId
    id: number
    name: string
    type: string
    sizeInBytes: number
    entitiesCount: number
}

export interface DoSearchResultItem {
    id: number
    documentId: number
    name: string
    content: string
}

export interface DoSearchRes {
    results: DoSearchResultItem[]
}

export interface FilesPayload {
    files: File[]
}

export interface SemanticTextEntity {
    id: number
    name: string
    content: string
}

export interface SearchCollectionInfo {
    semanticEntitiesCount: number
    name: string
    documentsCount: number
}

export interface ExtractPdfReq {
    /** @format binary */
    file: File
    implementation: 'JAVA_HELPER_SERVICE' | 'AZURE'
}

export interface ChatMessage {
    role: string
    content: string
}

export interface ChatRequest {
    messages: ChatMessage[]
    collectionId: number
}

export interface ChatbotThoughtProcessExplanation {
    queryPrompt: string
    query: string
    answerPrompt?: ChatMessage[]
    answer?: string
}

export interface DataPoint {
    name: string
    content: string
}

export interface AskResponse {
    qaId: string
    answer: string
    thoughts: ChatbotThoughtProcessExplanation
    dataPoints: DataPoint[]
    error?: string
    noKnowledge: boolean
}

export interface ChatbotSettings {
    isDefault: boolean
    id: number
    name: string
    chatDescription: string
    generateQueryPrompt: string
    answerPrompt: string
    questions: string[]
    searchDocLimit: number
    compensationQueryUnquoting: boolean
}

export interface RateAnswerReq {
    id: string
    /**
     * @min 1
     * @max 5
     */
    rating: number
}

export interface ChatbotQA {
    id: string
    user: string
    collectionName: string
    collectionId: number
    question: string
    answer: string
    noKnowledge: boolean
    /**
     * @min 1
     * @max 5
     */
    rating?: number
    /** @format date-time */
    createdAt: string
    /** @format date-time */
    updatedAt: string
}

export interface LlmTokenUsage {
    total: number
    prompt: number
    completion: number
}

export interface ChatbotQAAdditionalInfo {
    searchResults: DataPoint[]
    queryPrompt: string
    query: string
    answerPrompt?: ChatMessage[]
    queryTokenUsage: LlmTokenUsage
    answerTokenUsage: LlmTokenUsage
    tokenUsage: LlmTokenUsage
    answerLlmId: string
    queryLlmId: string
    /** @format date-time */
    createdAt: string
    /** @format date-time */
    updatedAt: string
}

export interface ChatbotSettingsWriteReq {
    name: string
    chatDescription: string
    generateQueryPrompt: string
    answerPrompt: string
    questions: string[]
    searchDocLimit: number
    compensationQueryUnquoting: boolean
}

export type ContentType = {}

export type RequestParams = {
    fileDownload?: boolean
}

export const ContentType = {
    Json: 'application/json',
    FormData: 'multipart/form-data',
}

export type Request = {
    path: string
    method: 'GET' | 'POST' | 'PUT' | 'DELETE'
    format?: 'json' | 'document'
    query?: any
    body?: any
    type?: string
    secure?: boolean
}

export type HttpClient = {
    request: <Data, A = any>(req: Request) => Promise<Data>
}

/**
 * @title ai-proto api
 * @version 1.0.0
 * @contact
 */
export class MyApi {
    http: HttpClient

    constructor(http: HttpClient) {
        this.http = http
    }

    app = {
        /**
         * No description
         *
         * @tags app
         * @name GetInfo
         * @request GET:/api/info
         */
        getInfo: (params: RequestParams = {}) =>
            this.http.request<AppInfo, any>({
                path: `/api/info`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags app
         * @name GetConfig
         * @request GET:/api/app/config
         */
        getConfig: (params: RequestParams = {}) =>
            this.http.request<ConfigInfo, any>({
                path: `/api/app/config`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    }
    config = {
        /**
         * No description
         *
         * @tags config
         * @name GetConfig
         * @request GET:/api/current
         */
        getConfig: (params: RequestParams = {}) =>
            this.http.request<ConfigInfo, any>({
                path: `/api/current`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    }
    spa = {
        /**
         * No description
         *
         * @tags spa
         * @name GetSpaConf
         * @request GET:/api/spa/conf
         */
        getSpaConf: (params: RequestParams = {}) =>
            this.http.request<FrontendConf, any>({
                path: `/api/spa/conf`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    }
    auth = {
        /**
         * No description
         *
         * @tags auth
         * @name InitiateLogin
         * @request GET:/api/auth/login-initiate
         */
        initiateLogin: (
            query: {
                cb: string
            },
            params: RequestParams = {},
        ) =>
            this.http.request<LoginInitiateRes, any>({
                path: `/api/auth/login-initiate`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags auth
         * @name Login
         * @request GET:/api/auth/login
         */
        login: (params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/auth/login`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags auth
         * @name LoginCb
         * @request GET:/api/auth/login/cb
         */
        loginCb: (params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/auth/login/cb`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags auth
         * @name Logout
         * @request POST:/api/auth/logout
         */
        logout: (params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/auth/logout`,
                method: 'POST',
                ...params,
            }),
    }
    user = {
        /**
         * No description
         *
         * @tags user
         * @name UserInfo
         * @request GET:/api/user/info
         */
        userInfo: (params: RequestParams = {}) =>
            this.http.request<CurrentUser, any>({
                path: `/api/user/info`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    }
    search = {
        /**
         * No description
         *
         * @tags search
         * @name UpdateCollection
         * @request PUT:/api/search/collection/{collectionId}
         */
        updateCollection: (collectionId: number, data: CollectionUpdateCmd, params: RequestParams = {}) =>
            this.http.request<Collection, any>({
                path: `/api/search/collection/${collectionId}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name DeleteCollection
         * @request DELETE:/api/search/collection/{collectionId}
         */
        deleteCollection: (collectionId: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/search/collection/${collectionId}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name GetCollectionInfo
         * @request GET:/api/search/collection/{collectionId}
         */
        getCollectionInfo: (collectionId: number, params: RequestParams = {}) =>
            this.http.request<SearchCollectionInfo, any>({
                path: `/api/search/collection/${collectionId}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name CreateCollection
         * @request POST:/api/search/collection
         */
        createCollection: (data: CollectionCreateCmd, params: RequestParams = {}) =>
            this.http.request<number, any>({
                path: `/api/search/collection`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name GetCollections
         * @request GET:/api/search/collection
         */
        getCollections: (params: RequestParams = {}) =>
            this.http.request<Collection[], any>({
                path: `/api/search/collection`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name GetCollectionSourceDocs
         * @request GET:/api/search/collection/{collectionId}/source-docs
         */
        getCollectionSourceDocs: (collectionId: number, params: RequestParams = {}) =>
            this.http.request<SourceDocument[], any>({
                path: `/api/search/collection/${collectionId}/source-docs`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name DeleteCollectionSourceDoc
         * @request GET:/api/search/collection/{collectionId}/source-doc/{docId}
         */
        deleteCollectionSourceDoc: (collectionId: number, docId: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/search/collection/${collectionId}/source-doc/${docId}`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name Query
         * @request GET:/api/search/query/{collectionId}
         */
        query: (
            collectionId: number,
            query: {
                q: string
            },
            params: RequestParams = {},
        ) =>
            this.http.request<DoSearchRes, any>({
                path: `/api/search/query/${collectionId}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name SetFromPdf
         * @request POST:/api/search/upload/{collectionId}
         */
        setFromPdf: (collectionId: number, data: FilesPayload, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/search/upload/${collectionId}`,
                method: 'POST',
                body: data,
                type: ContentType.FormData,
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name GetDocumentTextEntities
         * @request GET:/api/search/document/{id}/text-entities
         */
        getDocumentTextEntities: (id: number, params: RequestParams = {}) =>
            this.http.request<SemanticTextEntity[], any>({
                path: `/api/search/document/${id}/text-entities`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags search
         * @name GetDocumentContent
         * @request GET:/api/search/document/{id}
         */
        getDocumentContent: (id: number, params: RequestParams = {}) =>
            this.http.request<File, any>({
                path: `/api/search/document/${id}`,
                method: 'GET',
                format: 'document',
                ...params,
            }),
    }
    pdf = {
        /**
         * No description
         *
         * @tags pdf
         * @name Extract
         * @request PUT:/api/pdf/extract
         */
        extract: (data: ExtractPdfReq, params: RequestParams = {}) =>
            this.http.request<string[], any>({
                path: `/api/pdf/extract`,
                method: 'PUT',
                body: data,
                type: ContentType.FormData,
                format: 'json',
                ...params,
            }),
    }
    vector = {
        /**
         * No description
         *
         * @tags vector
         * @name Embed
         * @request GET:/api/vector/embed
         */
        embed: (
            query: {
                text: string
            },
            params: RequestParams = {},
        ) =>
            this.http.request<void, any>({
                path: `/api/vector/embed`,
                method: 'GET',
                query: query,
                ...params,
            }),
    }
    chat = {
        /**
         * No description
         *
         * @tags chat
         * @name Msg
         * @request POST:/api/chat/msg
         */
        msg: (data: ChatRequest, params: RequestParams = {}) =>
            this.http.request<AskResponse, any>({
                path: `/api/chat/msg`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags chat
         * @name GetSettings
         * @request GET:/api/chat/settings
         */
        getSettings: (params: RequestParams = {}) =>
            this.http.request<ChatbotSettings, any>({
                path: `/api/chat/settings`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags chat
         * @name SaveSettings
         * @request PUT:/api/chat/settings
         */
        saveSettings: (data: ChatbotSettings, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/chat/settings`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags chat
         * @name RateAnswer
         * @request POST:/api/chat/rate-answer
         */
        rateAnswer: (data: RateAnswerReq, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/chat/rate-answer`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags chat
         * @name GetAnswers
         * @request GET:/api/chat/answers
         */
        getAnswers: (
            query: {
                number: number
                size: number
                sortBy?: string
                sortDir?: 'asc' | 'desc'
                search: string
            },
            params: RequestParams = {},
        ) =>
            this.http.request<
                {
                    items: ChatbotQA[]
                    pageCount: number
                    totalItemCount: number
                    page: number
                    first: boolean
                    last: boolean
                },
                any
            >({
                path: `/api/chat/answers`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags chat
         * @name GetAdditionalInfo
         * @request GET:/api/chat/answer/{id}/additional-info
         */
        getAdditionalInfo: (id: string, params: RequestParams = {}) =>
            this.http.request<ChatbotQAAdditionalInfo, any>({
                path: `/api/chat/answer/${id}/additional-info`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    }
    chatbotSettings = {
        /**
         * No description
         *
         * @tags chatbotSettings
         * @name GetAll
         * @request GET:/api/chatbot/settings
         */
        getAll: (params: RequestParams = {}) =>
            this.http.request<ChatbotSettings[], any>({
                path: `/api/chatbot/settings`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags chatbotSettings
         * @name Add
         * @request POST:/api/chatbot/settings
         */
        add: (data: ChatbotSettingsWriteReq, params: RequestParams = {}) =>
            this.http.request<ChatbotSettings[], any>({
                path: `/api/chatbot/settings`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags chatbotSettings
         * @name SaveSettings
         * @request PUT:/api/chatbot/settings/{id}
         */
        saveSettings: (id: number, data: ChatbotSettingsWriteReq, params: RequestParams = {}) =>
            this.http.request<ChatbotSettings, any>({
                path: `/api/chatbot/settings/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags chatbotSettings
         * @name Delete
         * @request DELETE:/api/chatbot/settings/{id}
         */
        delete: (id: number, params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/api/chatbot/settings/${id}`,
                method: 'DELETE',
                ...params,
            }),
    }
    llm = {
        /**
         * No description
         *
         * @tags llm
         * @name AskVersion
         * @request GET:/api/llm/version
         */
        askVersion: (
            query: {
                id: string
            },
            params: RequestParams = {},
        ) =>
            this.http.request<string, any>({
                path: `/api/llm/version`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),
    }
    metrics = {
        /**
         * No description
         *
         * @name Index
         * @request GET:/metrics
         */
        index: (params: RequestParams = {}) =>
            this.http.request<void, any>({
                path: `/metrics`,
                method: 'GET',
                ...params,
            }),
    }
    subappInvpar = {
        /**
         * No description
         *
         * @tags subapp_invpar
         * @name GetFoo
         * @request GET:/api/subapp/invpar/foo
         */
        getFoo: (params: RequestParams = {}) =>
            this.http.request<string, any>({
                path: `/api/subapp/invpar/foo`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    }
}
